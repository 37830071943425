import React from 'react';
import { NavbarBrand } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default function Title({ text }) {
    return (
      <NavbarBrand className="text-nowrap" tag={Link} to="/">
        { text }
      </NavbarBrand>
    );
}

Title.propTypes = {
    text: PropTypes.string.isRequired,
};
