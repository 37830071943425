import { useState } from 'react';

export default function useInput(defaultState) {
    const [get, set] = useState(defaultState);
    const input = {
        onChange: (e) => set(e.target.value),
        value: get,
    };

    return [get, input, set];
}
