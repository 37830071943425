export default (challenge) => {
    if (!challenge) return null;

    const { rounds } = challenge;

    if (!rounds) return null;

    return rounds
        .filter(({ startTimestamp }) => Date.parse(startTimestamp) <= new Date());
};
