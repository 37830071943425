import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

const Row = styled('tr')`
    cursor: pointer;
`;

export default function LinkRow({ children, to }) {
    const [clicked, setClicked] = useState(false);
    const click = () => setClicked(true);

    return (
      <Row onClick={click}>
        { clicked && <Redirect push to={to} /> }
        {children}
      </Row>
    );
}

LinkRow.propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

LinkRow.defaultProps = {
    children: [],
};
