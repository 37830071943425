export default (challenge) => {
    if (!challenge) return null;

    const { rounds } = challenge;

    if (!rounds) return null;

    return rounds
        .filter(({ startTimestamp, endTimestamp }) =>
            Date.parse(startTimestamp) <= new Date()
            && new Date() <= Date.parse(endTimestamp))[0];
};
