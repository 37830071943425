import React, { useState, useContext } from 'react';
import {
    Input, Button, InputGroup, InputGroupAddon,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { TeamContext } from '../../contexts/teamContext';
import onEnter from '../../etc/onEnter';
import useInput from '../../etc/useInput';
import Error from '../../blocks/Error';

export default function LoginField() {
    const [pending, setPending] = useState(false);
    const [error, setError] = useState(null);
    const [secret, inputSecret] = useInput('');
    const teamCtx = useContext(TeamContext);

    const login = () => {
        setError(null);
        setPending(true);

        teamCtx
            .signIn(secret)
            .catch(setError);

        setPending(false);
    };

    return (
      <InputGroup className="col-xl-6 col-lg-8 col-md-9 col-sm-8 p-0">
        { error && error.code !== 401 && <Error {...error} close={() => setError(null)} /> }
        <Input
          className="border-0 form-control"
          placeholder="Secret Key"
          invalid={error !== null}
          {...inputSecret}
          {...onEnter(login)}
        />
        <InputGroupAddon addonType="append">
          <Button
            className="btn-success"
            disabled={pending}
            onClick={login}
          >
            Войти
          </Button>
        </InputGroupAddon>
      </InputGroup>
    );
}

LoginField.propTypes = {
    className: PropTypes.string,
};

LoginField.defaultProps = {
    className: '',
};
