import React, { Fragment } from 'react';

export default function NotAuthorized() {
    return (
      <Fragment>
        { !localStorage.secret && (
          <div>
            <h1>Вы не авторизированы</h1>
            Для доступа к этой странице, войдите с помощью секретного ключа.
          </div>
        )}
      </Fragment>
    );
}
