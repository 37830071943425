import React, { useContext, useState, useEffect } from 'react';
import { Button, Table } from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { ChallengeContext } from '../contexts/challengeContext';
import { TaskContext } from '../contexts/taskContext';
import LinkRow from '../blocks/LinkRow';
import Error from '../blocks/Error';
import NotAuthorized from '../blocks/NotAuthorized';

export default function Status() {
    const ctx = useContext(ChallengeContext);
    const taskCtx = useContext(TaskContext);
    const [rounds, setRounds] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        setRounds(ctx.getStartedRounds());
    }, [ctx]);

    const dateFormat = (dateString) => {
        const date = new Date(dateString);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    };

    const byStartTime = (r1, r2) => {
        const date1 = new Date(r1.startTimestamp);
        const date2 = new Date(r2.startTimestamp);

        if (date1 < date2) return 1;
        if (date1 > date2) return -1;
        return 0;
    };

    const takenByType = (round, type) => {
        if (taskCtx.status[round] === undefined) return 0;

        let sum = 0;
        for (let status = 0; status < 3; status += 1) {
            if (taskCtx.status[round][type][status] !== undefined) {
                sum += taskCtx.status[round][type][status];
            }
        }
        return sum;
    };

    const takenByRound = (round) => {
        if (taskCtx.status[round] === undefined) return 0;

        let count = 0;
        for (const type in taskCtx.status[round])
            for (const status in [0, 1, 2]) {
                if (taskCtx.status[round][type][status] !== undefined) {
                    count += taskCtx.status[round][type][status];
                }
            }
        return count;
    };

    const byTypeStatus = (round, type, status) =>
        taskCtx.status[round] !== undefined && taskCtx.status[round][type][status] !== undefined
            ? taskCtx.status[round][type][status]
            : 0;

    const sum = (array) => array.reduce((a, b) => a + b, 0);
    const isOver = (r) => new Date(r.endTimestamp) < Date.now();
    const canChooseType = (round) => ctx.getCanChooseType(round) && !isOver(round);
    const getTasksCount = (round, statuses) => {
        let count = 0;
        for (const type in taskCtx.status[round])
            for (const status in statuses) {
                if (taskCtx.status[round][type][statuses[status]] !== undefined) {
                    count += taskCtx.status[round][type][statuses[status]];
                }
            }
        return count;
    };

    return (
      <div>
        { error && <Error {...error} close={() => setError(null)} />}
        <h1>Этапы</h1>
        <NotAuthorized />
        { rounds && rounds.sort(byStartTime).map((round) => (
          <div key={round.id}>
            <div className="d-flex py-3 align-items-center flex-wrap">
              <h2 className="m-0">{round.id}</h2>
              <div className="px-3">
                <p className="m-0">
                  <b>Начало:</b> {dateFormat(round.startTimestamp)}
                </p>
                <p className="m-0">
                  <b>Конец:</b> {dateFormat(round.endTimestamp)}
                </p>
              </div>
              <Button
                color={isOver(round) ? 'secondary' : 'primary'}
                tag={Link}
                to="/tasks"
                disabled={isOver(round)}
              >
                {isOver(round) ? 'Этап завершен' : 'Взять задачу'}
              </Button>
            </div>
            <ReactMarkdown source={round.description} />
            <Table responsive hover>
              <thead>
                <tr>
                  <th>Тип</th>
                  <th>Всего</th>
                  <th>Взято</th>
                  <th><span role="img" aria-label="completed">✅</span></th>
                  <th><span role="img" aria-label="failed">❌</span></th>
                  <th><span role="img" aria-label="waiting">⏳</span></th>
                  <th>Очки</th>
                </tr>
              </thead>
              <tbody>
                {round.taskTypes.map((type) => (
                  <LinkRow key={type.id} to={`/status/${round.id}/${type.id}`}>
                    <td className="text-nowrap">
                      <span className="pr-3">{type.id}</span>
                      { canChooseType(round)
                        && (
                        <Button
                          className="btn-sm py-0 mt-n1"
                          color="primary"
                          tag={Link}
                          to={`/tasks?type=${type.id}`}
                        >
                            +
                        </Button>
                        )}
                    </td>
                    <td>{type.tasksCount}</td>
                    <td>{takenByType(round.id, type.id)}</td>
                    <td>{byTypeStatus(round.id, type.id, 1)}</td>
                    <td>{byTypeStatus(round.id, type.id, 2)}</td>
                    <td>{byTypeStatus(round.id, type.id, 0)}</td>
                    <td>{byTypeStatus(round.id, type.id, 'points')}</td>
                  </LinkRow>
                ))}
                <tr className="bg-light">
                  <th>Итого</th>
                  <th>{sum(round.taskTypes.map((t) => t.tasksCount))}</th>
                  <th>{takenByRound(round.id)}</th>
                  <th>{getTasksCount(round.id, [1])}</th>
                  <th>{getTasksCount(round.id, [2])}</th>
                  <th>{getTasksCount(round.id, [0])}</th>
                  <th>{getTasksCount(round.id, ['points'])}</th>
                </tr>
              </tbody>
            </Table>
          </div>
        ))}
      </div>
    );
}
