import React from 'react';
import { Collapse as Collapsed } from 'reactstrap';
import PropTypes from 'prop-types';

export default function Collapse({ children, isOpen }) {
    return (
      <Collapsed className="flex-sm-row-reverse" isOpen={isOpen} navbar>
        {children}
      </Collapsed>
    );
}

Collapse.propTypes = {
    children: PropTypes.arrayOf(PropTypes.node).isRequired,
    isOpen: PropTypes.bool.isRequired,
};
