import safeFetch from '../extenstions/safeFetch';
import url from '../extenstions/url';
import fromResult from '../extenstions/fromResult';

const getChallenge = (id, secret) => safeFetch(`${url.challenges}/${id}?secret=${secret}`);

export default (id, secret, setChallenge) => ((id === undefined)
    ? fromResult(Promise.reject,
        () => setChallenge({}))
    : getChallenge(id, secret)
        .then(setChallenge));
