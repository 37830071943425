import safeFetch from '../extenstions/safeFetch';
import setLocalStorage from '../extenstions/setLocalStorage';
import url from '../extenstions/url';

const getTeam = (secret) => safeFetch(`${url.teams}?secret=${secret}`);

export default (secret, setTeam) => ((secret === undefined)
    ? Promise.reject({ code: 400, error: 'undefined-secret' })
    : getTeam(secret)
        .then(setLocalStorage)
        .then(setTeam));
