import React, {Fragment, useContext, useEffect, useState} from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import { ListGroup, ListGroupItem, ListGroupItemHeading, InputGroup, Input, Button, InputGroupAddon, Alert } from 'reactstrap';
import ListItem from '../blocks/ListItem';
import { TaskContext } from '../contexts/taskContext';
import Error from '../blocks/Error';
import useInput from '../etc/useInput';
import onEnter from '../etc/onEnter';
import Deadline from '../blocks/Deadline';

const color = ['secondary', 'success', 'danger'];


export default function Task({ match }) {
    const [error, setError] = useState(null);
    const [pending, setPending] = useState(false);
    const [answer, inputAnswer] = useInput('');
    const ctx = useContext(TaskContext);
    const { id } = match.params;
    const [deadlineEnd, setDeadlineEnd] = useState(false);

    useEffect(() => {
        if (id !== ctx.task.id) {
            ctx.setTaskById(id, localStorage.secret)
                .catch((err) => {
                    ctx.task.id = null;
                    setError(err);
                });
        }
    }, [ctx, id]);

    const send = () => {
        setError(null);
        setPending(true);

        ctx.sendAnswer(answer, localStorage.secret)
            .then(() => {
              setPending(false);
            })
            .catch((e) => {
              setPending(false);
              setError(e);
            });
    };

    const handleSend = (e) => {
        e.preventDefault();
        send();
    };

    return (
      <div>
        { error && <Error {...error} close={() => setError(null)} />}
        { ctx.task.id === id && (
          <Fragment>
            { deadlineEnd && (
              <Alert color="danger">
                <h4>Время истекло</h4>
                С этого момента вы не сможете получить очки за эту задачу
              </Alert>
            )}
            { ctx.task.status !== undefined && ctx.task.status !== 0 && (
              <Alert color={color[ctx.task.status]}>
                { ctx.task.status === 1 && <h4>Задача решена верно</h4> }
                { ctx.task.status === 2 && <h4>Ответ неверный</h4> }
              </Alert>
            )}
            <div className="row">
              <div className="col-lg-3 col-md-4 order-md-1 pl-md-0">
                <ListGroup>
                  <ListItem title="ID задачи" color={color[ctx.task.status]}>
                    <small>{id}</small>
                  </ListItem>
                  <ListItem className="d-flex" title="Тип" value={ctx.task.typeId} />
                  <ListItem className="d-flex" title="Цена" value={ctx.task.cost} />
                  { ctx.task.status === 0 && (
                    <ListItem className="d-flex" title="Время">
                      <Deadline task={ctx.task} onDeadline={setDeadlineEnd} />
                    </ListItem>
                  )}
                  { ctx.task.status !== 0 && (
                    <ListItem className="d-flex" title="Получено" value={ctx.task.points} />
                  )}
                </ListGroup>
              </div>
              <div className="col-lg-9 col-md-8 order-md-0 mt-n1 mt-md-0">
                <ListGroup>
                  <ListGroupItem>
                    <ListGroupItemHeading>Задача</ListGroupItemHeading>
                    <ReactMarkdown source={ctx.task.userHint} />
                  </ListGroupItem>
                  <ListItem>
                    <pre style={{ whiteSpace: 'pre-wrap', textAlign: 'left' }}>
                      {ctx.task.question}
                    </pre>
                  </ListItem>
                  { ctx.task.status === 0 && (
                    <ListItem className="p-0">
                      <InputGroup>
                        <Input className="border-0" placeholder="Ваш ответ" {...inputAnswer} {...onEnter(send)} />
                        <InputGroupAddon addonType="append">
                          <Button color="primary" className="rounded-0" disabled={pending} onClick={handleSend}>
                            Отправить
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </ListItem>
                  )}
                  { ctx.task.status !== 0 && (
                      <ListItem title="Ваш ответ" value={ctx.task.teamAnswer} />
                  )}
                </ListGroup>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    );
}

Task.propTypes = PropTypes.shape({
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
}).isRequired;
