import { useState, useMemo } from 'react';
import { createContext } from 'react';
import getNewTask from './taskActions/getNewTask';
import setTaskById from './taskActions/setTaskById';
import sendAnswer from './taskActions/sendAnswer';
import getStatus from './taskActions/getStatus';
import getTakenTasks from './taskActions/getTakenTasks';

export function useTaskContext() {
    const [task, setTask] = useState({});
    const [takenTasks, setTakenTasks] = useState([]);
    
    const taskContext = useMemo(() => ({
        getNewTask: (round, type, secret) => getNewTask(round, type, secret, setTask),
        setTaskById: (id, secret) => setTaskById(id, secret, setTask),
        sendAnswer: (answer, secret) => sendAnswer(task.id, answer, secret, setTask),
        getStatus: (secret) => getStatus(secret, setTakenTasks),
        getTakenTasks: (secret, round, type, status, page, setTasks) => getTakenTasks(secret, round, type, status, page, setTasks),
        status: takenTasks,
        task,
    }), [task, takenTasks]);
    return taskContext;
}

export const TaskContext = createContext({});
