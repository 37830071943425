import React, {
    useContext, useMemo, Fragment,
} from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ChallengeContext } from '../contexts/challengeContext';
import NotAuthorized from '../blocks/NotAuthorized';
import TasksList from '../blocks/TasksList';

export default function StatusTask({ match }) {
    const { roundId, typeId } = match.params;
    const ctx = useContext(ChallengeContext);

    const round = useMemo(() => ctx.getRoundById(roundId), [ctx, roundId]);
    const isOver = useMemo(() => !round || new Date(round.endTimestamp) < Date.now(), [round]);

    return (
      <div>
        <h1>Задачи</h1>
        <div className="d-flex py-3 align-items-center flex-wrap">
          <h2 className="pr-3">{roundId} &mdash; {typeId}</h2>
          { !isOver && <Button color="primary" tag={Link} to={`/tasks?type=${typeId}`}>Взять задачу</Button> }
        </div>
        <NotAuthorized />
        {localStorage.secret && (
          <Fragment>
            <TasksList round={roundId} type={typeId} status={0} />
            <TasksList round={roundId} type={typeId} status={1} />
            <TasksList round={roundId} type={typeId} status={2} />
          </Fragment>
        )}
      </div>
    );
}

StatusTask.propTypes = PropTypes.shape({
    match: PropTypes.shape({
        params: PropTypes.shape({
            roundId: PropTypes.string.isRequired,
            typeId: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
}).isRequired;
