import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import React from 'react';
import NavMenu from './NavMenu';

export default function Layout({ children }) {
    return (
      <div>
        <NavMenu />
        <Container>
          { children }
        </Container>
      </div>
    );
}

Layout.propTypes = {
    children: PropTypes.arrayOf(PropTypes.node).isRequired,
};
