function byDate({ startTimestamp: strA }, { startTimestamp: strB }) {
    const dateA = Date.parse(strA);
    const dateB = Date.parse(strB);

    if (dateA > dateB) return 1;
    if (dateA < dateB) return -1;
    return 0;
}

export default (challenge) => {
    if (!challenge) return null;

    const { rounds } = challenge;

    if (!rounds) return null;

    const round = rounds
        .filter(({ startTimestamp: start }) => new Date() < Date.parse(start))
        .sort(byDate)[0];

    if (!round) return null;

    const { startTimestamp } = round;

    return new Date(Date.parse(startTimestamp)).toLocaleString();
};
