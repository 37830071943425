import React, { useState, useContext, useEffect } from 'react';
import {Button, ButtonGroup, Table} from 'reactstrap';
import LinkRow from './LinkRow';
import Deadline from './Deadline';
import { TaskContext } from '../contexts/taskContext';

export default function TasksList({ round, type, status }) {
    const [page, setPage] = useState(0);
    const taskCtx = useContext(TaskContext);
    const [tasks, setTasks] = useState([]);

    useEffect(() => {
        setTasks([]);
        taskCtx.getTakenTasks(localStorage.secret, round, type, status, page, setTasks);
    }, [page, round, status, taskCtx, type]);

    const title = (status === 0)
        ? 'Задачи без ответа'
        : (status === 1)
            ? 'Решённые задачи'
            : 'Неверно решённые задачи';
    const thirdColumn = (status === 0)
        ? 'Время'
        : 'Очки';
    const dateFormat = (dateString) => {
        if (!dateString) return '–';
        const date = new Date(dateString);
        return (
            <span>
              {date.toLocaleDateString()}
              &nbsp;
              {date.toLocaleTimeString()}
            </span>
        );
    };
    const byTakenTime = (t1, t2) => {
        const date1 = new Date(t1.takeTimestamp);
        const date2 = new Date(t2.takeTimestamp);
        if (date1 < date2) return 1;
        if (date1 > date2) return -1;
        return 0;
    };

    return (
      <div>
        <div className="d-flex py-3 align-items-center flex-wrap">
          <h4 className="pr-3">{title}</h4>
          <ButtonGroup>
            <Button color="primary" disabled={page < 1} onClick={() => setPage(page - 1)}>&larr;</Button>
            <Button color="primary" disabled>{page * 10 + 1} &mdash; {page * 10 + Math.max(tasks.length, 1)}</Button>
            <Button color="primary" disabled={!tasks.length} onClick={() => setPage(page + 1)}>&rarr;</Button>
          </ButtonGroup>
        </div>
        <Table responsive hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Взята</th>
              <th>{thirdColumn}</th>
            </tr>
          </thead>
          <tbody>
            {tasks.sort(byTakenTime).map((task) => (
              <LinkRow key={task.id} to={`/tasks/${task.id}`}>
                <td style={{ maxWidth: '10vw', overflow: 'hidden' }}>{task.id}</td>
                <td>{dateFormat(task.takeTimestamp)}</td>
                {status === 0 && (
                  <td>
                    <Deadline task={task} />
                  </td>
                )}
                {status !== 0 && (
                  <td>
                    {task.points}
                  </td>
                )}
              </LinkRow>
            ))}
          </tbody>
        </Table>
      </div>
    );
}
