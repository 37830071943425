import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import styled from 'styled-components';

const Header = styled(ToastHeader)`
    .close {
        font-size: 1.2rem;
    }
`;

const icons = {
    error: 'danger',
    warning: 'warning',
};

const title = {
    error: 'Ошибка сервера',
    warning: 'Внимание',
};

let timer;

export default function Error({ code, error, text, level, close }) {
    const [getState, setState] = useState(true);
    const hide = () => {
        clearTimeout(timer);
        setState(false);
        setTimeout(close, 500);
    };

    clearTimeout(timer);
    timer = setTimeout(hide, 3000);

    return (
      <div className="d-flex fixed-top justify-content-center p-3">
        <Toast isOpen={getState}>
          <Header toggle={hide} className="d-flex" tagClassName="flex-fill" icon={icons[level]}>
            <div>
              { title[level] }
            </div>
          </Header>
          <ToastBody>
            <b>{ code }-{ error }</b>
            { text && (
              <React.Fragment>
                <br />
                { text }
              </React.Fragment>
            )}
          </ToastBody>
        </Toast>
      </div>
    );
}

Error.propTypes = {
    code: PropTypes.number.isRequired,
    error: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    level: PropTypes.string.isRequired,
    text: PropTypes.string,
};

Error.defaultProps = {
    text: '',
};
