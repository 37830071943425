import styled from 'styled-components';

export default styled.button.attrs({
    className: 'btn',
    type: 'button',
})`
    width: 110px;
    text-align: center;
    display: inline;
`;
