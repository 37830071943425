import React from 'react';
import TeamWidget from '../blocks/TeamWidget';
import ChallengeDescription from '../blocks/ChallengeDescription';
import CommonDescription from '../blocks/CommonDescription';

export default function Home() {
    return (
      <div>
        { localStorage.secret
          && (
          <div className="row">
            <div className="col-lg-3 order-lg-1 mb-3">
              <TeamWidget />
            </div>
            <div className="col-lg-9 order-lg-0">
              <ChallengeDescription />
            </div>
          </div>
          )}
        { !localStorage.secret
          && <CommonDescription />}
      </div>
    );
}
