export default (challenge, id) => {
    if (!challenge) return null;

    const { rounds } = challenge;

    if (!rounds) return null;

    const results = rounds.filter((r) => r.id === id);

    if (results.length === 0) return null;

    return results[0];
};
