import safeFetch from '../extenstions/safeFetch';
import url from '../extenstions/url';
import parseResponseTime from '../extenstions/parseResponseTime';

const getTaskWithType = (round, type, secret) => safeFetch(
    `${url.tasks.get}?round=${round}&type=${type}&secret=${secret}`, { method: 'POST' },
);

const getTaskWithoutType = (round, secret) => safeFetch(
    `${url.tasks.get}?round=${round}&secret=${secret}`, { method: 'POST' },
);

const getTask = (round, type, secret) => (type !== undefined
    ? getTaskWithType(round, type, secret)
    : getTaskWithoutType(round, secret));

export default (round, type, secret, setTask) =>
    getTask(round, type, secret)
        .then(parseResponseTime)
        .then(setTask);
