export default function onEnter(func) {
    return {
        onKeyDown: (e) => {
            if (e.keyCode === 13) {
                e.preventDefault();
                func(e.target.value);
            }
        },
    };
}
