import React from 'react';
import { ListGroupItem, ListGroupItemHeading } from 'reactstrap';
import PropTypes from 'prop-types';

export default function ListItem({ title, value, children, className, color }) {
    return (
      <ListGroupItem className={className} color={color}>
        { title && <ListGroupItemHeading>{title}</ListGroupItemHeading> }
        <span className="text-right w-100">
          {value}
          {children}
        </span>
      </ListGroupItem>
    );
}

ListItem.propTypes = {
    title: PropTypes.string,
    color: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

ListItem.defaultProps = {
    title: undefined,
    value: '',
    children: [],
    color: 'white',
    className: '',
};
