import React, { useContext, useMemo } from 'react';
import { Table } from 'reactstrap';
import { TeamContext } from '../contexts/teamContext';
import { ChallengeContext } from '../contexts/challengeContext';

export default function Rating() {
    const ctx = useContext(TeamContext);
    const challengeCtx = useContext(ChallengeContext);
    const rounds = useMemo(() => (challengeCtx.challenge.rounds
        ? challengeCtx.challenge.rounds.map((round) => round.id)
        : []), [challengeCtx]);
    const sum = (team) => Object.values(team.points).reduce((a, b) => a + b, 0);

    const bySum = (team1, team2) => {
        if (sum(team1) < sum(team2)) return 1;
        if (sum(team1) > sum(team2)) return -1;
        return 0;
    };

    return (
      <div>
        <h1>Рейтинг</h1>
        <Table responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Команда</th>
              <th>Состав</th>
              { rounds.map((round, roundIndex) => (
                <th key={roundIndex}>{round}</th>
              ))}
              <th>Очки</th>
            </tr>
          </thead>
          <tbody>
            { ctx.teams
                .filter((team) => team.challengeId === challengeCtx.challenge.id)
                .sort(bySum)
                .map((team, teamIdex) => (
                  <tr key={teamIdex}>
                    <td>{teamIdex + 1}</td>
                    <td>{team.teamName}</td>
                    <td>{team.members.join(', ')}</td>
                    { rounds.map((round, roundIndex) => <td key={roundIndex}>{ team.points[round] }</td>)}
                    <td>{sum(team)}</td>
                  </tr>
                ))}
          </tbody>
        </Table>
      </div>
    );
}
