import { NavItem, NavLink as OldNavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

export default function NavLink({ to, title, onClick }) {
    return (
      <NavItem>
        <OldNavLink tag={Link} to={to} onClick={onClick}>{title}</OldNavLink>
      </NavItem>
    );
}

NavLink.propTypes = {
    to: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};

NavLink.defaultProps = {
    onClick: null,
};
