import React from 'react';
import PropTypes from 'prop-types';

export default function FormContainer({ children }) {
    return (
      <div className="col-md-7 col-lg-5 p-0">
        {children}
      </div>
    );
}

FormContainer.propTypes = {
    children: PropTypes.arrayOf(PropTypes.node).isRequired,
};
