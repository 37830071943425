import React, { useContext, useState, useMemo } from 'react';
import { Alert, InputGroup, InputGroupAddon } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '../controls/FormButton';
import { TaskContext } from '../contexts/taskContext';
import Error from '../blocks/Error';
import { ChallengeContext } from '../contexts/challengeContext';
import NotAuthorized from '../blocks/NotAuthorized';

export default function GetNewTask({ location }) {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [pending, setPending] = useState(false);

    const taskCtx = useContext(TaskContext);
    const challengeCtx = useContext(ChallengeContext);

    const round = useMemo(() => challengeCtx.getCurrentRound(), [challengeCtx]);
    const canChooseType = useMemo(() => challengeCtx.getCanChooseType(round), [challengeCtx, round]);
    const taskTypes = useMemo(() => challengeCtx.getTaskTypes(round), [challengeCtx, round]);
    const nextRoundTime = useMemo(() => challengeCtx.getNextRoundTime(), [challengeCtx]);

    const search = location.search
        .slice(1)
        .split('&')
        .map((x) => x.split('='))
        .filter((x) => x[0] === 'type')[0];

    const type = search !== undefined ? search[1] : undefined;

    const selectedType = () => (canChooseType
        ? document.getElementById('type').value
        : undefined);

    const handleSend = (e) => {
        e.preventDefault();
        setError(null);
        setPending(true);
        taskCtx
            .getNewTask(round.id, selectedType(), localStorage.secret)
            .then(() => {
              setPending(false);
              setSuccess(true);
            })
            .catch((e) => {
              setPending(false);
              setError(e);
            });
    };

    return (
      <React.Fragment>
        <h1>Получить задачу</h1>
        { success && <Redirect push to={`/tasks/${taskCtx.task.id}`} />}
        { error && <Error {...error} close={() => setError(null)} /> }
        <NotAuthorized />
        { !round && localStorage.secret && (
          <React.Fragment>
            <h4>Нельзя получить задачу</h4>
            Активного этапа сейчас нет.
            <br />
            Ближайший начнется в { nextRoundTime || 'следующей жизни' }.
          </React.Fragment>
        )}
        { round && (
          <div className="row">
            <div className="col-lg-6 col-md-6 order-lg-1 order-md-1 mb-3">
              <Alert color="warning">
                <h4>Внимание</h4>
                Как только вы получите задачу, начнется отсчёт времени.<br />
                Вы не получите очков за задачу, отправив ее по истечении указанного времени.
              </Alert>
            </div>
            <div className="col-lg-6 col-md-6">
              <form>
                { canChooseType && (
                  <div>
                    <h5>Тип задачи</h5>
                    <InputGroup>
                      <select className="form-control" id="type" defaultValue={type}>
                        {taskTypes.map((id) => <option key={id}>{ id }</option>)}
                      </select>
                      <InputGroupAddon addonType="append">
                        <Button disabled={pending} className="btn-primary" onClick={handleSend}>
                          Получить
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </div>
                )}
                { !canChooseType && (
                  <React.Fragment>
                    <p>
                      В данном этапе выбирать тип задачи нельзя.
                      <br />
                      Вы получите задачу случайного типа.
                    </p>
                    <Button disabled={pending} className="btn-primary" onClick={handleSend}>
                      Получить
                    </Button>
                  </React.Fragment>
                )}
              </form>
            </div>
          </div>
        )}
      </React.Fragment>
    );
}

GetNewTask.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string.isRequired,
    }).isRequired,
};
