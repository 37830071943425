import React from 'react';
import FormContainer from '../controls/FormContainer';

export default function CommonDescription() {
    return (
      <FormContainer>
        <h1>Привет</h1>
        <p>Это сервис для проведения онлайн-соревнований.</p>
        <p>
          Команде выдаются задачи различных типов.
          Им предлагается написать бота, автоматизирующего решения этих задач.
        </p>
        <p>
          Чем больше типов задач умеет решать бот, чем быстрее и оптимальнее он их решает,
          тем больше очков получит команда.
        </p>
        <p>
          Для начала, зарегистрируй команду на соревнование или войди, используя секретный ключ.
        </p>
      </FormContainer>
    );
}
