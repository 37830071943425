import React, {useState, useContext, Fragment} from 'react';
import { Redirect } from 'react-router-dom';
import Button from '../controls/FormButton';
import useInput from '../etc/useInput';
import Error from '../blocks/Error';
import FormContainer from '../controls/FormContainer';
import onEnter from '../etc/onEnter';
import { TeamContext } from '../contexts/teamContext';
import { SettingsContext } from '../contexts/settingsContext';

export default function SignUp() {
    const [newName, inputNewName, setNewName] = useInput('');
    const [teamName, inputTeamName] = useInput('');

    const [success, setSuccess] = useState(false);
    const [pending, setPending] = useState(false);
    const [error, setError] = useState(null);
    const [members, setMembers] = useState([]);

    const teamCtx = useContext(TeamContext);
    const settingsCtx = useContext(SettingsContext);

    const removeName = (index) => setMembers(members.filter((val, i) => i !== index));
    const addName = (name) => setMembers(members.concat(name));
    const handleAdd = () => {
        addName(newName);
        setNewName('');
    };
    const handleSend = (e) => {
        e.preventDefault();

        setError(null);
        setPending(true);

        teamCtx
            .signUp(JSON.stringify({
                members,
                teamName,
                challengeId: settingsCtx.settings.challengeId,
            }))
            .then(() => setSuccess(true))
            .catch(setError);

        setPending(false);
    };

    const isTeamNameValid = (field) => field !== null && field !== '' && /^[A-Za-z]+[0-9]+_[0-9]+ /.test(field);
    const isMemberValid = (field) => field !== null && field !== '';
    const isFormValid = () => isTeamNameValid(teamName) && members.length > 0 && members.every(isMemberValid);

    return (
      <FormContainer>
        {success && <Redirect push to="/signupSuccess" />}
        { error && <Error {...error} close={() => setError(null)} />}
        { settingsCtx.settings.canRegister && (
          <Fragment>
            <h1>Создать команду</h1>
            <form>
              <div className="form-group">
                Идентификатор и название в формате «X0_0 Название»
                <input className="form-control" {...inputTeamName} />
              </div>
              Участники
              {members.map((name, id) => (
                  <div key={id} className="form-group input-group">
                    <input className="form-control" value={name} disabled />
                    <div className="input-group-append">
                      <Button className="btn-outline-danger" onClick={() => removeName(id)}>
                        Исключить
                      </Button>
                    </div>
                  </div>
              ))}
              <div className="form-group input-group">
                <input className="form-control" {...inputNewName} {...onEnter(handleAdd)} />
                <div className="input-group-append">
                  <Button className="btn-outline-success" onClick={handleAdd}>
                    Добавить
                  </Button>
                </div>
              </div>
              <Button
                  disabled={!isFormValid() || pending}
                  className="btn-primary"
                  onClick={handleSend}
              >
                Отправить
              </Button>
            </form>
          </Fragment>
        )}
        { !settingsCtx.settings.canRegister && <h1>Регистрация закрыта</h1> }
      </FormContainer>
    );
}
