import React from 'react';
import { Navbar } from 'reactstrap';
import PropTypes from 'prop-types';

export default function NavBar({ children }) {
    return (
      <Navbar className="navbar-expand-sm box-shadow mb-3 bg-dark" dark>
        {children}
      </Navbar>
    );
}

NavBar.propTypes = {
    children: PropTypes.node.isRequired,
};
