import setLocalStorage from '../extenstions/setLocalStorage';
import safeFetch from '../extenstions/safeFetch';
import url from '../extenstions/url';

export default (form, setTeam) => safeFetch(url.teams, {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: form,
})
    .then(setLocalStorage)
    .then(setTeam);
