const errorInfo = ({ status }, { errorName, errorDescription, errorLevel }) => ({
    code: status,
    error: errorName,
    text: errorDescription,
    level: errorLevel,
});

const checkStatus = (response) => new Promise((resolve, reject) => response
    .json()
    .catch(() => ({
        errorName: 'internal-server-error',
        errorDescription: 'Мы не смогли выполнить этот запрос.\n'
            + 'Попробуйте повторить его позже или обратитесь к организаторам.',
        errorLevel: 'error',
    }))
    .then((json) => (response.ok
        ? resolve(json)
        : reject(errorInfo(response, json)))));

export default (url, params) => fetch(url, params)
    .then(checkStatus);
