import { useState, useMemo } from 'react';
import { createContext } from 'react';
import signIn from './teamActions/signIn';
import signOut from './teamActions/signOut';
import signUp from './teamActions/signUp';
import getRating from './teamActions/getRating';

export function useTeamContext() {
    const [team, setTeam] = useState({});
    const [teams, setTeams] = useState([]);
    
    const teamContext = useMemo(() => ({
        signIn: (secret) => signIn(secret, setTeam),
        signUp: (form) => signUp(form, setTeam),
        signOut: () => signOut(setTeam),
        getRating: () => getRating(setTeams),
        teams,
        team,
    }), [team, teams]);
    return teamContext;
  }

export const TeamContext = createContext({});
