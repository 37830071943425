import React from 'react';
import ReactMarkdown from 'react-markdown';
import { ChallengeContext } from '../contexts/challengeContext';

export default function ChallengeDescription() {
    return (
      <ChallengeContext.Consumer>
        {(ctx) => <ReactMarkdown source={ctx.challenge.description} />}
      </ChallengeContext.Consumer>
    );
}
