import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import Layout from './blocks/Layout';
import SignUp from './pages/SignUp';
import SignUpSuccess from './pages/SignUpSuccess';
import { TeamContext, useTeamContext } from './contexts/teamContext';
import { TaskContext, useTaskContext } from './contexts/taskContext';
import { ChallengeContext, useChallengeContext } from './contexts/challengeContext';
import GetNewTask from './pages/GetNewTask';
import Task from './pages/Task';
import Status from './pages/Status';
import StatusTask from './pages/StatusTask';
import Error from './blocks/Error';
import Rating from './pages/Rating';
import { SettingsContext, useSettingsContext } from './contexts/settingsContext';
import Profile from './pages/Profile';

export default function App() {
    const [error, setError] = useState(null);

    const teamCtx = useTeamContext();
    const taskCtx = useTaskContext();
    const challengeCtx = useChallengeContext();
    const settingsCtx = useSettingsContext();

    const secret = localStorage.secret;

    useEffect(() => {
        teamCtx
            .signIn(secret)
            .catch((e) => {
                if (e.error !== 'undefined-secret') setError(e);
            });
        teamCtx.getRating();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [secret]);

    useEffect(() => {
        challengeCtx
            .update(teamCtx.team.challengeId, secret)
            .catch(setError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teamCtx.team, secret]);

    useEffect(() => {
        taskCtx
            .getStatus(secret)
            .catch((er) => {
                if (er.code !== 401) setError(er);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teamCtx.team, secret]);

    useEffect(() => {
        settingsCtx
            .getSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <SettingsContext.Provider value={settingsCtx}>
        <TaskContext.Provider value={taskCtx}>
          <TeamContext.Provider value={teamCtx}>
            <ChallengeContext.Provider value={challengeCtx}>
              <Layout>
                {error && <Error {...error} close={() => setError(null)} /> }
                <Route exact path="/" component={Home} />
                <Route exact path="/signup" component={SignUp} />
                <Route exact path="/signupSuccess" component={SignUpSuccess} />
                <Route exact path="/rating" component={Rating} />
                <Route exace path="/profile" component={Profile} />
                <Switch>
                  <Route exact path="/status" component={Status} />
                  <Route path="/status/:roundId/:typeId" component={StatusTask} />
                </Switch>
                <Switch>
                  <Route path="/tasks/:id" component={Task} />
                  <Route exact path="/tasks" component={GetNewTask} />
                </Switch>
              </Layout>
            </ChallengeContext.Provider>
          </TeamContext.Provider>
        </TaskContext.Provider>
      </SettingsContext.Provider>
    );
}
