import safeFetch from '../extenstions/safeFetch';
import url from '../extenstions/url';

export default (id, answer, secret, setTask) =>
    safeFetch(`${url.tasks.get}/${id}?secret=${secret}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ answer }),
    })
        .then(setTask);
