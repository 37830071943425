import React, { useContext } from 'react';
import { TeamContext } from '../contexts/teamContext';
import { TaskContext } from '../contexts/taskContext';

export default function TeamWidget() {
    const ctx = useContext(TeamContext);
    const taskCtx = useContext(TaskContext);

    const getPoints = () => Object.values(ctx.team.points).reduce((a, b) => a + b, 0);

    function getTasksCount(statuses) {
        let count = 0;
        for (const round in taskCtx.status) {
            for (const type in taskCtx.status[round])
                for (const status in statuses) {
                    if (taskCtx.status[round][type][statuses[status]] !== undefined) {
                        count += taskCtx.status[round][type][statuses[status]];
                    }
                }
        }
        return count;
    };

    return (
      <div className="card" style={{maxWidth: '255px'}}>
        <div className="card-header">
          <h5 className="mb-0"> {ctx.team.teamName} </h5>
        </div>
        <div className="card-body">
          Очков: {ctx.team.points && getPoints()}
          <hr />
          Взято задач: {getTasksCount([0,1,2])}
          <div className="d-flex pt-3">
            <div className="flex-grow-1 text-center">
              <span role="img" aria-label="completed">✅</span>{getTasksCount([1])}
            </div>
            <div className="flex-grow-1 text-center">
              <span role="img" aria-label="failed">❌</span>{getTasksCount([2])}
            </div>
            <div className="flex-grow-1 text-center">
              <span role="img" aria-label="waiting">⏳</span>{getTasksCount([0])}
            </div>
          </div>
        </div>
      </div>
    );
}
