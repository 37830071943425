import { useState, useMemo } from 'react';
import { createContext } from 'react';
import update from './challengeActions/update';
import getCurrentRound from './challengeActions/getCurrentRound';
import getTaskTypes from './challengeActions/getTaskTypes';
import getNextRoundTime from './challengeActions/getNextRoundTime';
import getCanChooseType from './challengeActions/getCanChooseType';
import getStartedRounds from './challengeActions/getStartedRounds';
import getRoundById from './challengeActions/getRoundById';

export function useChallengeContext() {
    const [challenge, setChallenge] = useState({});
    
    const challengeContext = useMemo(() => ({
        update: (id, secret) => update(id, secret, setChallenge),
        getCurrentRound: () => getCurrentRound(challenge),
        getTaskTypes: (round) => getTaskTypes(round),
        getNextRoundTime: () => getNextRoundTime(challenge),
        getCanChooseType: (round) => getCanChooseType(round),
        getStartedRounds: () => getStartedRounds(challenge),
        getRoundById: (id) => getRoundById(challenge, id),
        challenge,
    }), [challenge]);
    return challengeContext;
}

export const ChallengeContext = createContext({});
