import React from 'react';
import FormContainer from '../controls/FormContainer';

export default function SignUpSuccess() {
    const { secret } = localStorage;

    return (
      <FormContainer>
        <h1 className="text-nowrap">Команда зарегистрирована</h1>
        <div className="py-4">
          Ваш секретный ключ:
          <input disabled className="form-control" value={secret} />
        </div>
        <div className="alert alert-warning">
          Этот ключ используется для авторизации на сервере.
          <br />
          Передавайте его <b>ТОЛЬКО</b> участникам вашей команды.
        </div>
      </FormContainer>
    );
}
