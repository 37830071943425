import { Container, NavbarToggler } from 'reactstrap';
import './NavMenu.css';
import React, { useState, useEffect, useContext } from 'react';
import NavBar from '../controls/navbar/Navbar';
import LoginField from '../controls/navbar/LoginField';
import { TeamContext } from '../contexts/teamContext';
import { ChallengeContext } from '../contexts/challengeContext';
import Title from '../controls/navbar/Title';
import Collapse from '../controls/navbar/Collapse';
import NavLink from '../controls/navbar/NavLink';
import {SettingsContext} from '../contexts/settingsContext';

export default function NavMenu() {
    const [collapsed, setCollapsed] = useState(true);
    const [title, setTitle] = useState(undefined);
    const teamCtx = useContext(TeamContext);
    const challengeCtx = useContext(ChallengeContext);
    const settingsCtx = useContext(SettingsContext);

    useEffect(() => setTitle(challengeCtx.challenge.title),
        [challengeCtx]);

    const toggleNavbar = () => setCollapsed(!collapsed);

    return (
      <header>
        <NavBar>
          <Container>
            <Title text={title || 'Сервис онлайн-соревнований'} />
            <NavbarToggler onClick={toggleNavbar} />
            <Collapse isOpen={!collapsed}>
              <ul className="navbar-nav">
                {!localStorage.secret && settingsCtx.settings.canRegister && (
                  <React.Fragment>
                    <NavLink to="/signup" title="Регистрация" />
                  </React.Fragment>
                )}
                {localStorage.secret && (
                  <React.Fragment>
                    <NavLink to="/rating" title="Рейтинг" />
                    <NavLink to="/status" title="Задачи" />
                    <NavLink to="/profile" title={teamCtx.team.teamName || 'Профиль'} />
                    <NavLink to="/" title="Выйти" onClick={teamCtx.signOut} />
                  </React.Fragment>
                )}
              </ul>
              {!localStorage.secret && <LoginField />}
            </Collapse>
          </Container>
        </NavBar>
      </header>
    );
}
