import { useState, useMemo } from 'react';
import { createContext } from 'react';
import getSettings from './settingsActions/getSettings';

export function useSettingsContext() {
    const [settings, setSettings] = useState({});
    
    const settingsContext = useMemo(() => ({
        settings,
        getSettings: () => getSettings(setSettings),
    }), [settings]);
    return settingsContext;
}

export const SettingsContext = createContext({});
