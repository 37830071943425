import React, { useContext } from 'react';
import { TeamContext } from '../contexts/teamContext';
import FormContainer from '../controls/FormContainer';

export default function Profile() {
    const ctx = useContext(TeamContext);

    return (
      <FormContainer>
        <h1>{ctx.team && ctx.team.teamName}</h1>
        <h4>Ваш секретный ключ</h4>
        <input disabled className="form-control my-2" value={localStorage.secret} />
        <h4>Состав команды</h4>
        { ctx.team.members && ctx.team.members.map((member, index) => <input disabled className="form-control my-2" key={index} value={member} />)}
      </FormContainer>
    );
}
